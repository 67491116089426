import { PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { changePlanOrder } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";

type getShopReviewPageChangeOrderStateType = {
    changeOrderResponse: any,
    loading: boolean;
    error: any;
}
const initialState: getShopReviewPageChangeOrderStateType = {
    changeOrderResponse: {},
    loading: false,
    error: null,
}

export const changeOrder  = createAsyncThunk(
  "reviewOrder/ChangeOrder",
  async (requestPayload : any,cartId: any) => {
    try {
      const reduxStore = store.getState();
      const headersnew = reduxStore?.brightspeedToken?.headers;
      const headers = {
        ...headersnew,
        cartId: reduxStore?.cartSlice?.cartResponse?.cartId?.slice(1, -1),
        "Flow": "change",
        Authorization:  `Bearer ${reduxStore?.user?.jwtToken}`,
      }
      // Adding Payload details
      const params = {
        "": [API_URLS?.changeOrder],
      };
     
      const response = await changePlanOrder(params, requestPayload, headers);
      const messageStatus = response?.data?.messageStatus?.find((item: any) => {
          if ("statusCode" in item) {
              return item;
          }
      })
      if (messageStatus?.statusCode === 200) {
          return response?.data?.orderResponse;
      } else {
        return response.data;
      } 
    } catch (error) {
      throw error;
    }
  }
);

export const reviseOrder  = createAsyncThunk(
    "reviewOrder/ChangeOrder",
    async (requestPayload : any,cartId: any) => {
      try {
        const reduxStore = store.getState();
        const headers = reduxStore?.brightspeedToken?.headers;
          
        // Adding Payload details
  
        const params = {
          "": [API_URLS?.reviseOrder],
        };
       
        const response = await changePlanOrder(params, requestPayload, headers);
        const messageStatus = response?.data?.messageStatus?.find((item: any) => {
            if ("statusCode" in item) {
                return item;
            }
        })
        if (messageStatus?.statusCode === 200) {
            return response?.data?.orderResponse;
        } else {
          return response.data;
        } 
      } catch (error) {
        throw error;
      }
    }
  );

const getChangeOrderStateSlice = createSlice({
  name: "getChangeOrderState/Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(changeOrder.fulfilled, (state, action) => {
        state.changeOrderResponse = action.payload;
        state.loading = false;
        state.error = null;
      });
      builder.addCase(changeOrder.rejected, (state, action) => {
        state.changeOrderResponse = null;
        state.loading = false;
        state.error = action.error;
      });
  },
})

  export const {} = getChangeOrderStateSlice.actions;
  export default getChangeOrderStateSlice.reducer;
  
  